import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/Home'
  },
  {
    name: 'contentLayout',
    path: '/contentLayout',
    component: () => import(/* webpackChunkName: "contentLayout" */ '@/views/ContentLayout.vue'),
    children:[
      {
        path: '/Home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home/index.vue')
      },
      {
        path: '/About',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About/About.vue')
      },
      {
        path: '/Product',
        name: 'Product',
        component: () => import(/* webpackChunkName: "about" */ '../views/Product/Product.vue')
      },
      {
        path: '/BusinessLayout',
        name: 'businessLayout',
        component: () => import(/* webpackChunkName: "about" */ '../views/BusinessLayout/index.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach(() => {
  //判断当前登录是移动端还是pc端
  if(window.innerWidth < 500  ){
    window.location.href="https://h5.malipo-fintech.com/";
    return;
  }
})
export default router
