<template>
  <div class="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script lang="ts">
  import {nextTick, provide, ref} from "vue";
  export default {
    name: "layout",
    setup() {
      let isRouterAlive = ref(true);
      /*
    * 刷新页面
    * */
      const reload = () => {
        isRouterAlive.value = false;
        nextTick(() => {
          isRouterAlive.value = true;
        })
      }
      provide("reload", reload);
      return{
        isRouterAlive,
        reload
      }
    }
  }
</script>

<style lang="less">
</style>
