import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "./common/less/common.less";
import $ from "jquery";
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LocomotiveScroll from "locomotive-scroll";
import { CSSPlugin } from "gsap/CSSPlugin";
gsap.registerPlugin(CSSPlugin);
import "./common/less/locomotive-scroll.css";
import "./common/font/font.less";//字体库
import 'element-plus/dist/index.css'
import { createPinia } from 'pinia'
const app=createApp(App);
app.use(router);
app.use(createPinia());
app.config.globalProperties._$=$;
app.config.globalProperties.$gsap=gsap;
app.config.globalProperties.$ScrollTrigger=ScrollTrigger;
app.config.globalProperties.$LocomotiveScroll=LocomotiveScroll;
app.mount('#app');
